<template>
  <div id='certificate-print' class='certificate-print relative' style='height: 210mm; width: 297mm;'>
    <img :src='image64' class='w-full h-full z-0 top-0 absolute' id='certificate-image-bg' />
    <div v-if='isCmccmeEvent'
     class='flex flex-col justify-center items-center gap-y-1 w-full h-full absolute z-10'
      style='font-family: serif; margin-top: -72px;'>
      <div class='text-4xl font-bold text-gray-900 capitalize mb-2'>{{participantName}}</div>
      <div class='text-3xl font-normal text-gray-700'>{{profile.hospitalName}}</div>
    </div>
    <div v-else
     class='flex flex-col justify-center items-center gap-y-1 w-full h-full absolute z-10'
      style='font-family: serif;'>
      <div class='text-5xl font-bold text-gray-900'>{{profile.firstName}} {{profile.lastName}}</div>
      <div class='text-3xl font-normal text-gray-700'>{{profile.hospitalName}}</div>
      <div class='text-xl font-normal text-gray-600'>{{profile.country}}</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import html2pdf from 'html2pdf.js'
import imageToBase64 from 'image-to-base64/browser'

export default {
  name: 'CertificateDownload',
  props: [
    'layout'
  ],
  data () {
    return {
      image64: '',
    }
  },
  computed: {
    ...mapState('users', [
      'profile',
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'isCmccmeEvent',
    ]),
    pdfOptions () {
      return {
        margin:       0,
        filename:     `${this.whichFilename}.pdf`,
        image:        { type: 'png', quality: 0.98 },
        html2canvas:  { scale: 2 },
        jsPDF:        { unit: 'in', format: 'a4', orientation: 'landscape' }
      }
    },
    whichImage () {
      return (this.$route.query.certificate_type == 'speaker') ? this.speakerCertificatePath : this.participantCertificatePath
    },
    whichFilename () {
      return (this.$route.query.certificate_type == 'speaker') ? 'speaker_certificate' : 'particiapant_certificate'
    },
    participantCertificatePath () {
      switch (this.showingEventId) {
        case 7:
          return `https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2022/certificate_template_participant.jpg`
        case 29:
          return `https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/cmccme/cmccme2023_certificate.jpg`
        case 40:
          return `https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/cmccme/cmccme2024_certificate.jpg`
        default: 
          return ''
      }
    },
    participantName () {
      if (this.showingEventId == 40) {
        return this.profile.name
      } else {
        return `${this.profile.firstName} ${this.profile.lastName}`
      }
    },
    speakerCertificatePath () {
      return `https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2022/certificate_template_speaker.jpg`
    },
  },
  methods: {
    ...mapActions('users', [
      'getProfile',
    ]),
    pdfCreate () {
      var element = document.getElementById('certificate-print')
      html2pdf().set(this.pdfOptions).from(element).save()
    },
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
    this.getProfile().then(() => {
      imageToBase64(this.whichImage).then(resp => {
        let image = document.getElementById('certificate-image-bg')
        image.src = `data:image/jpg;base64, ${resp}`
        image.onload = () => {
          this.pdfCreate()
        }
      })
    })
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
  },
}
</script>

<style lang='scss'>
  .certificate-print {
    page-break-inside: avoid;
    -webkit-print-color-adjust: exact;
  }
</style>
